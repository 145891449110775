// vue
import App from './app.vue'
// style
import './styl/index.styl'
// js
import { createApp } from 'vue'

// init vue app
// @ts-ignore
const app = createApp(App)

// mount vue app to app div
app.mount('#app')
