function validate (field: Field): boolean {
  // get field value
  const value = field.value()

  // check required property
  if (field.required && !value) {
    field.show_error('This field is required')
    return false
  }

  // validate types
  switch (field.type) {
    // validate email field
    case 'email':
      if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value as string)) {
        field.show_error('Invalid email address.')
        return false
      }
      break
    // validate telephone field
    case 'tel':
      if (!/^(\+)[1-9]{11}$/.test(value as string)) {
        field.show_error('Invalid phone number. Use int. format with country code +00 00 000 0000')
        return false
      }
      break
    // validate password field
    case 'password':
      if (field.attributes.min !== undefined && field.attributes.min > (value as string).length) {
        field.show_error(`Password is too short. Min ${field.attributes.min} characters`)
        return false
      }
      break
  }

  return true
}

type Field = {
  id:         string
  value:      () => string|number|boolean
  type:       string
  attributes: {[key: string]: string|number}
  required:   boolean
  show_error: (_: string) => void
}

export { Field, validate }
